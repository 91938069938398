import QualificationPeriodBanner from "components/components/Dashboard/QualificationPeriodBanner";
import React, { Fragment, useRef, useState } from "react";
import { Tag, Col, Row } from "../../components/components";
import HiddenSection from "../../components/components/Dashboard/HiddenSection";
import BatterySection from "../../components/components/Dashboard/BatterySection";
import MeQAccordion from "../../components/components/Dashboard/MeQAccordion";
import DealerGreeting from "../../components/components/Dashboard/DealerGreeting";
import DashboardLegend from "../../components/components/Dashboard/DashboardLegend";
import DealerSelectionTabs from "../../components/components/Dashboard/DealerSelectionTabs";
import { calcTaskProgress } from "utils/CommonUtils";
import { BaseDashboarProps } from "pages/ModelEDashboard";
import MeCPDashboard from "./MeCPDashboard";
import ChargingInfraSection from "../../components/components/Dashboard/ChargingInfraSections";

export const ENABLE_MECP = false;

const USADashboard = ({
	t,
	canShowQualificationBanner,
	countryCode,
	dealerCode,
	dashboardData,
	firstSection,
	dealerSectionsInfo,
	chargingInfraStatus,
	loaAndWaiverInfo,
	sectionsWithId,
	isQualified,
	dealerDocumentMapping,
	setExpandCallback,
	setCollapseCallback,
	setOnLoadCallback,
	isWeb,
	mecpDashboardData,
}: BaseDashboarProps) => {
	const [tabState, setTabState] = useState("meQDashTab");

	const { dealerName, maapEarnings, dataAsOf, marketArea, region, salesCode, sections, paCode: paCodeFromApi, zone } = dashboardData || {};
	const welcomeMsg = `${t("common.welcome")}, ${dealerName}`;

	const { mecpAchievement = false, mecpQualifier = false } = mecpDashboardData || {};
	const isMecpAchieved = typeof mecpAchievement === "boolean" ? mecpAchievement : mecpAchievement === "true";
	const isMecpQualified = typeof mecpQualifier === "boolean" ? mecpQualifier : mecpQualifier === "true";

	const tabSelection = (selectedTab: React.SetStateAction<string>) => {
		setTabState(selectedTab);
	};

	const containerRef: any = useRef(null);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const scrollToContainer = () => {
		if (containerRef && containerRef?.current) containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	const handleTabChange = (tabName: string) => {
		setTabState(tabName);
	};

	const chargingInfraSection = sectionsWithId?.[0].subSections?.find((s) => s.id?.toString() == "4");
	//const criteria = subSection?.criterias?.[0];

	return (
		<>
			<Fragment key={Math.random().toFixed(4)}>
				{/* Start Welcome Section */}
				{true && <QualificationPeriodBanner countryCode={countryCode} />}

				{/* <Col xs={12} className={"fds-m--y-1 fds-p--x-0"}>
					<Row className={"fds-flex--center "}>
						<Col xs={8} className={"fds-flex--center fds-flex-direction--column fds-p--b-3"}>
							<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-align--center fds-p--y-2"}>
								<DealerGreeting countryCode={countryCode} dealerCode={dealerCode} isQualified={isQualified} marketArea={marketArea} paCodeFromApi={paCodeFromApi} region={region} salesCode={salesCode} t={t} welcomeMsg={welcomeMsg} dataAsOf={dataAsOf} />
								<DashboardLegend countryCode={countryCode} hasLOA={Object.keys(loaAndWaiverInfo || {})?.includes("LOA")} hasWaiver={Object.keys(loaAndWaiverInfo || {})?.includes("OnWaiver")} t={t} />
							</Tag>
						</Col>
					</Row>
					<DealerSelectionTabs
						mecpAchievement={isMecpAchieved}
						mecpQualified={isMecpQualified}
						chargingInfraStatus={chargingInfraStatus}
						dealerSectionsInfo={dealerSectionsInfo}
						isQualified={isQualified}
						currentTabName={tabState}
						onTabChange={handleTabChange}
						isWeb={isWeb}
					/>
				</Col> */}

				<Row className={"fds-flex--center "}>
					<Col className={"fds-flex--center fds-flex-direction--column fds-p--b-3 h-100 fds-flex--center"}>
						<DealerGreeting countryCode={countryCode} dealerCode={dealerCode} isQualified={isQualified} marketArea={marketArea} paCodeFromApi={paCodeFromApi} region={region} salesCode={salesCode} zone={zone} t={t} welcomeMsg={welcomeMsg} dataAsOf={dataAsOf} />
						<div className={"fds-m--y-2"}>
							<BatterySection status={chargingInfraStatus} fmeQPercentage={dealerSectionsInfo?.meqCompletionPercent} />
						</div>
						<DashboardLegend countryCode={countryCode} hasLOA={Object.keys(loaAndWaiverInfo || {})?.includes("LOA")} hasWaiver={Object.keys(loaAndWaiverInfo || {})?.includes("OnWaiver")} t={t} />
					</Col>
				</Row>

				<Col ref={containerRef} xs={12} className={"fds-m--y-2 fds-p--x-0 dashTabContainer"}>
					{tabState === "meQDashTab" && (
						<div>
							{ENABLE_MECP && (
								<div className={"landingBatteryContainer"}>
									<BatterySection status={chargingInfraStatus} fmeQPercentage={dealerSectionsInfo?.meqCompletionPercent} />
								</div>
							)}
							<Col xs={12} className={"fds-m--x-2 fds-p--b-2"}>
								{sectionsWithId?.map((section, key) => (
									<MeQAccordion
										id={section?.slugId as any}
										key={section?.slugId}
										title={section?.title}
										dealer={dealerSectionsInfo}
										isQualified={isQualified}
										documents={dealerDocumentMapping}
										completionPercent={dealerSectionsInfo?.[`${section?.slugId}CompletionPercent`]}
										section={section}
										tasksCompleted={calcTaskProgress}
										expandPanel={setExpandCallback}
										collapsePanel={setCollapseCallback}
										onLoadPanelEvent={setOnLoadCallback}
										countryCode={countryCode}
										dataAsOf={dataAsOf}
										t={t}
									/>
								))}
							</Col>
						</div>
					)}
					{ENABLE_MECP && tabState === "mecpDashTab" && <MeCPDashboard t={t} data={mecpDashboardData} salesCode={salesCode as any} />}
				</Col>

				{!!chargingInfraSection?.id && (
					<Col xs={12} className={"fds-m--x-2 fds-p--b-2"}>
						<ChargingInfraSection countryCode={countryCode} sectionId={chargingInfraSection?.id} subSection={chargingInfraSection} />
					</Col>
				)}

				{tabState != "default" && (
					<div className={"w-100 fds-flex fds-flex--center"}>
						<button onClick={scrollToTop} className={"landing-subButton fds-color--primary fds-m--t-2"}>
							Scroll to Top <span className={`fds-font--ford-icons__chevron-down fds-icon--offset-right-md iconRotate`} />
						</button>
					</div>
				)}
			</Fragment>
		</>
	);
};

export default USADashboard;
