import { Fragment } from "react";
import { Breadcrumbs, Col, Row, Tag } from "../components/components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Reports = () => {
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	const { countryCode } = useSelector((state: RootState) => state.localePreferences);

	return (
		<Fragment>
			<Col xs={12} className={"fds-m--y-3"}>
				{(windowWidth as number) >= 1024 && <Breadcrumbs className={"fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"} cta={[{ href: "/", label: "Reports", disabled: true }]} />}
				<Row className={"fds-flex--center"}>
					<Col xs={12} className={"fds-flex--center fds-flex-direction--column fds-p--b-3 fds-p--x-0"}>
						<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-align--center fds-p--y-2"}>
							Reports
						</Tag>

						<Row className="fds-flex--center w-100">
							<Col sm={12} md={3} lg={3} className={"fds-m--y-3"}>
								<Link to={"/reports/masterstatus"}>
									<div className={"reportSectionButton"}>
										<span>Electric Vehicle Dashboard Master Status Report</span>
									</div>
								</Link>
							</Col>

							{countryCode !== "CAN" && (
								<Col sm={12} md={3} lg={3} className={"fds-m--y-3"}>
									<Link to={"/reports/phq"}>
										<div className={"reportSectionButton"}>STARS Training Certification and Course Reports</div>
									</Link>
								</Col>
							)}

							{countryCode !== "CAN" && false && (
								<Col sm={12} md={3} lg={3} className={"fds-m--y-3"}>
									<Link to={"#"}>
										<div className={"reportSectionButton"}>Level 3 Charging Equipment Program</div>
									</Link>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</Col>
		</Fragment>
	);
};

export default Reports;
